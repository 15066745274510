/* **** dark-theme **** */

.App.dark-theme {
  > .header .box{  
    background: var(--l-blue-dark2);
    box-shadow: 10px 0px 20px -10px rgb(0 0 0 / 75%);
    }
    > .header .content{
        background: var(--l-blue-dark2);
    }
    > .header .breadcrumbs {
        background: var(--l-blue-dark2);
    }
    & .header .openBread {
        transform: translateX(0);
    }

}

.App.light-theme {
    > .header .box{
        background: #5bc4d8;
    }
    > .header .content{
        background: #5bc4d8;
    }
    > .header .breadcrumbs{
        background: #5bc4d8;
    }
    & .header .openBread {
        transform: translateX(0);
    }
}


/* ***************** */

.header {
    font-size: calc(10px + 1.5vmin);
}

.header .box{
    position: fixed;
    left: 0;
    top: 0;
    width: 50px;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   
    z-index: 3;
}

 .header .box{
    position: fixed;
    left: 0;
    top: 0;
    width: 50px;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    z-index: 3;
} 

 .header .content{
    position: fixed;
    transition: 0.2s linear;
    padding-top: 50px;
    top: 95px;
    z-index: 2;
    width: 50px;
    clip-path: polygon(100% 05%, 100% 100%, 0 100%, 0 0);
    height: 100%;
    
    display: flex;
    flex-direction: column;
    box-shadow: 0px 10px 20px -10px rgb(0 0 0 / 75%);
}

.header svg {
    color: rgb(245 0 87) !important;
}

.header .open{
    left: 50px;
}

.header .closed{
    left: 0;
}

.header .on{
    height: 8px;
    width: 8px;
    margin: 5px;
    background: #78d018;
    border-radius: 100%;
}

.header .off{
    height: 8px;
    width: 8px;
    margin: 5px;
    background: #d01818;
    border-radius: 100%;
}

.header .breadcrumbs {
    position: fixed;
    z-index: 2;
    
    left: 50px;
    transform: translateX(calc(-100% + 35px));
    top: 0;
    clip-path: polygon(calc(100% - 20px) 0, 100% 50%, calc(100% - 20px) 100%, 0 100%, 0 0);
    transition-duration: 0.5s;
}

 .header .breadcrumbs {
    position: fixed;
    z-index: 2;
   
    left: 50px;
    transform: translateX(calc(-100% + 35px));
    top: 0;
    clip-path: polygon(calc(100% - 20px) 0, 100% 50%, calc(100% - 20px) 100%, 0 100%, 0 0);
    transition-duration: 0.5s;
}

.header .breadcrumbs span {
    padding-left: 10px;
}

.header .breadcrumbs span::after {
    content: "";
    background: #00000029;
    height: 100%;
    width: 20px;
    position: absolute;
    clip-path: polygon(25% 0, 75% 50%, 25% 100%, 0% 100%, 50% 50%, 0 0);
}

.header .breadcrumbs span:last-child::after {
    display: none;
}

.header .breadcrumbs span:nth-last-child(2)::after {
    display: none;
}

.header .maintenance {
    position: fixed;
    width: 100vw;
    height: 100vh;
    transform: translateX(-100vw);
    top: 0;
    left: 0;
    background: #004b59;
    z-index: 99;
    transition-duration: 0.5s;
    text-transform: uppercase;
    font-size: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header .m-Open {
    transform: translateX(0);
}
