.App.dark-theme {
   & .profiles-container{
      scrollbar-width: thin;
    }
    & .table_head {
      background-color: #001b38;
    }
    & .dark_row {
      background-color: #0f3d6e;
    }
    & .light_row {
      background-color: #026f84;
    }
    & .table_cell {
      color: white;
      padding: 0.5rem 1rem;
    }
    & .button{
      padding: 0.5rem 1rem;
    }
    & .top_row{
      font-weight: "600";
    }
    & .permissions_container{
      background-color: #fdfefe;
    }
  }