.App.dark-theme {
    & .profiles-container{
      scrollbar-width: thin;
    }
    & .table_head {
      background-color: #001b38;
    }
    & .dark_row {
      background-color: #015a6b;
    }
    & .light_row {
      background-color: #026f84;
    }
    & .table_cell {
      color: white;
      padding: 0.5rem 1rem;
    }
    & .button{
      padding: 0.5rem 1rem;
    }
    & .top_row{
      font-weight: "600";
    }
    & .permissions_container{
      background-color: #fdfefe;
    }
  }

.tag {
    background: transparent;
}

.tag .search {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 50px;
    padding: 15px;
    background-color: rgba(150,150,150,0.5);
    border-radius: 0px 0px 5px;
}

.tag .edit {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000059;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s;
    cursor: pointer;
}

.tag .edit:hover {
    opacity: 100;
}

.tag .important:after {
    background-image: url(https://assets.codepen.io/13471/sparkles.gif);
    background-blend-mode: overlay;
    mix-blend-mode: color-dodge;
    opacity: 0.2;
    content: "";
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
}