/* **** dark-theme **** */

.App.dark-theme {
  > .profile .infoBox {
    background: rgba(0, 0, 0, 0.65);
  } 
  > .profile .editBox {
    background: rgba(0, 0, 0, 0.4);
} 
  & .table_container{
    scrollbar-width: thin;
  }
  & .table_head {
    background-color: #001b38;
  }
  & .dark_row {
    background-color: #0f3d6e;
  }
  & .light_row {
    background-color: #026f84;
  }
  & .table_cell {
    color: white;
    padding: 0.5rem 1rem;
  }
  & .button{
    padding: 0.5rem 1rem;
  }
  & .top_row{
    font-weight: "600";
  }
  & .permissions_container{
    background-color: #fdfefe;
  }
}


.App.light-theme {
    > .profile .infoBox {
        background: rgba(43, 43, 43, 0.65);
    }
    > .profile .editBox {
        background: rgba(199, 199, 199, 0.4);
}
}

.profile {
    display: flex;
    align-items: center;
}

.profile .infoBox {
    
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    justify-content: space-evenly;
}



.profile .infoBox .id {
    position: absolute;
    top: 0;
    left: 0;
    background: #1976d2;
    clip-path: polygon(0 0, 0% 100%, 100% 0);
    padding: 5px 30px 30px 10px;
}

.profile .infoBox .title {
    margin-right: auto;
    margin-left: -20px;
    padding-right: 30px;
    padding-left: 10px;
    clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
    font-size: 20px;
    width: 220px;
    text-align: left;
    background: #1976d2;
}

.profile .editBox {
    padding: 10px;
    border-radius: 0 6px 6px 0;
    width: 250px;
    height: 375px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.profile .editBox {
    background: rgba(199, 199, 199, 0.4);
    padding: 10px;
    border-radius: 0 6px 6px 0;
    width: 250px;
    height: 375px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}