/*  ********* dark-theme ********* */
.App.dark-theme {
    > .social .top-selector {
        background: var(--l-blue-dark2);
    }
    > .social .links{
        background: rgba(0, 0, 0, 0.65);
    }
    
} 


/*  ********* light-theme ********** */
.App.light-theme {
    > .social .top-selector {
        background: #5bc4d8;
    }
    > .social .links {
        background: rgba(0, 0, 0, 0.23);
    }
}


/* ******************************************************** */
.social {
    width: 100%;
    display: flex;
    height: 100%;
    padding-top: 60px;
}

.social .box {
    width: 100%;
    height: max-content;
    margin-left: 50px;
    line-height: 30px;
    font-size: 20px;
    justify-content: center;
}

.social .delete {
    position: absolute;
    display: flex;
    opacity: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    cursor: pointer;
}

.social .delete:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.4);
}

.social .import{
    flex: 1;
    margin: 20px;
    height: 200px;
}

.social .import .errors {
    max-width: 59vw;
    text-align: left;
    overflow: scroll;
    height: 60vh;
    margin-top: 30px;
}

.social .import .errors .titles {
    font-size: 30px;
    font-weight: 600;
    margin: 10px;
}

.social .import .errors .error-item {
    white-space: pre-wrap;
    font-size: 15px;
    background: white;
    color: black;
    border-radius: 5px;
    overflow: hidden;
    padding: 5px;
    margin-bottom: 5px;
}

.social .items {
    display: flex;
    flex-wrap: wrap;
}

.social .site-settings {
    display: flex;
    margin-left: 20px;
    justify-content: space-around;
}

.social .items .master {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    font-weight: 500;
    background: rgb(66, 66, 66);
    padding: 5px;
    border-radius: 0 0 0 5px;
}

.social .items .type {
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
    font-weight: 500;
    background: rgba(0, 0, 0, 0.6);
    padding: 5px;
    line-height: 36px;
    width: 100%;
}

.social .item {
    background-color: #231E39;
    box-shadow: 0px 10px 20px -10px rgb(0 0 0 / 75%);
    width: 430px;
    color: white;
    margin: auto;
    margin-bottom: 20px;
}

.social .item .top {
    display: flex;
    justify-content: space-between;
    background: #1F1A36;
    padding: 10px;
}

.social .item .top .id {
    margin-left: auto;
}

.social .item .top .master {
}

.social .item .mid {
    padding: 0 10px;
    display: flex;
}

.social .item .mid .frame {
    height: 150px;
    width: 150px;
    position: relative;
    overflow: hidden;
}

.social .item .mid .frame img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-height: 150px;
}

.social .item .mid .info {
    padding: 0 10px;
    text-align: left;
}

.social .item .bot {
    display: flex;
    justify-content: space-around;
    padding: 10px;
}

.social .persona-edit {
    display: flex;
    margin-top: 100px;
    color: white;
}

.social .top-selector-container {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
    top: 0;
}
.social .top-selector {
    display: flex;
    height: 40px;
    left: 50%;
    position: fixed;
    top: 0;
    transform: translateX(-50%);
    z-index: 1;
    
    clip-path: polygon(0 0, 100% 0, calc(100% - 15px) 100%, 15px 100%);
}


.social .top-selector .items {
    border-right: rgba(0, 0, 0, 0.5) 2px solid;
    padding: 0 10px;
    font-size: 15px;
    height: 40px;
    text-transform: uppercase;
    text-wrap: nowrap;
    cursor: pointer;
    align-content: center;
    transition: 0.5s
}

.social .top-selector .items:last-child {
    border-right: none;
    padding-right: 20px;
}

.social .top-selector .items:first-child {
    padding-left: 20px;
}

.social .top-selector .selected {
    background: rgba(0, 0, 0, 0.35);
}


.social .settings {
    position: fixed;
    top: 0;
    left: 50px;
    display: flex;
    flex-direction: column;
    width: 300px;
    background: var(--l-blue);
    padding: 10px;
    border-radius: 0 0 10px 0;
    color: black;
    max-height: 100vh;
    overflow-y: auto;
}

.social .settings .buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.social .settings .spacer {
    margin-bottom: 10px;
}

.social .links {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    height: 55px;
    z-index: 10;
    align-content: center;
    align-items: center;
    
    border-radius: 0 0 0 10px;
    padding: 10px;
}

.social .links {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    height: 55px;
    z-index: 10;
    align-content: center;
    align-items: center;
    
    border-radius: 0 0 0 10px;
    padding: 10px;
}

.social .links .spacer {
    margin-right: 10px
}

.social .post-edit {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.social .prompts-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
}

.social .prompts {
    background: #6892d7;
    padding: 20px;
    border-radius: 10px 0;
}

.social .prompts .prompt-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.social .prompts .prompt-item {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.social .prompts .spacer {
    margin-bottom: 10px;
}
